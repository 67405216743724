import React from "react";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import Header from "../components/Header/Header";
import ChoosePackageItem from "../components/QuotationGenerator/ChoosePackageItem";
import styles from "../components/QuotationGenerator/choosePackageItem.module.css"; // Import CSS module

const BoqPackageItem = () => {
  return (
    <div className={styles.container}>
      <Header />
      <SideNavigation />
      <ChoosePackageItem />
    </div>
  );
};

export default BoqPackageItem;
