import React from 'react';
import Modal from 'react-modal';
import styles from '../Tables.module.css';

function EditUserModal({
    isOpen,
    onRequestClose,
    formData,
    errors,
    currentStep,
    handleChange,
    handlePermissionChange,
    nextStep,
    prevStep,
    handleSubmit,
}) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Edit User Modal"
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <div className={styles.modalContent}>
                <h2>Edit User</h2>
                {currentStep === 1 && (
                    <form
                        className={styles.form}
                        onSubmit={(e) => {
                            e.preventDefault();
                            nextStep();
                        }}
                    >
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={errors.name ? styles.errorInput : ''}
                        />
                        {errors.name && <p className={styles.error}>{errors.name}</p>}

                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className={errors.phoneNumber ? styles.errorInput : ''}
                        />
                        {errors.phoneNumber && <p className={styles.error}>{errors.phoneNumber}</p>}

                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? styles.errorInput : ''}
                        />
                        {errors.email && <p className={styles.error}>{errors.email}</p>}

                        <label htmlFor="department">Department</label>
                        <select
  id="department"
  name="department"
  value={formData.department}
  onChange={handleChange}
  className={errors.department ? styles.errorInput : ''}
>
  <option value="">Select Department</option>
  <option value="Insight Sales">Insight Sales</option>
  <option value="Head of Insight Sales">Head of Insight Sales</option>
  <option value="Junior of Insight Sales">Junior of Insight Sales</option>
  <option value="Intern">Intern</option>
  <option value="Head Sales">Head Sales</option>
  <option value="Head">Head</option>
  <option value="Vertical Head">Vertical Head</option>
  <option value="Executive">Executive</option>
  <option value="Senior Sales Manager">Senior Sales Manager</option>
  <option value="HR">HR</option>
  <option value="Tech">Tech</option>
  <option value="Accounts">Accounts</option>
  <option value="Marketing">Marketing</option>
  <option value="Digital Marketing">Digital Marketing</option>
  <option value="Content Writer">Content Writer</option>
  <option value="Content Creator">Content Creator</option>
</select>

                        {errors.department && <p className={styles.error}>{errors.department}</p>}

                        <label htmlFor="designation">Designation</label>
                        <select
                            id="designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            className={errors.designation ? styles.errorInput : ''}
                        >
                            <option value="">Select Designation</option>
                            <option value="Junior">Junior</option>
                            <option value="Senior">Senior</option>
                        </select>
                        {errors.designation && <p className={styles.error}>{errors.designation}</p>}

                        <div className={styles.formGroupBottom}>
                            <button type="button" onClick={onRequestClose} className={styles.closeButton}>
                                Close
                            </button>
                            <button type="submit" className={styles.nextButton}>
                                Next
                            </button>
                        </div>
                    </form>
                )}
                {currentStep === 2 && (
                    <div>
                        <h3>Permissions</h3>
                        <table className={styles.permissionsTable}>
                            <thead>
                                <tr>
                                    <th>Access</th>
                                    <th>Editor</th>
                                    <th>Viewer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(formData.permissions).map(([category, permission]) => (
                                    <tr key={category}>
                                        <td>{category.charAt(0).toUpperCase() + category.slice(1)}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={permission.editor}
                                                onChange={(e) => handlePermissionChange(e, category, 'editor')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={permission.viewer}
                                                onChange={(e) => handlePermissionChange(e, category, 'viewer')}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.formGroupBottom}>
                            <button type="button" onClick={prevStep} className={styles.backButton}>
                                Back
                            </button>
                            <button type="button" onClick={handleSubmit} className={styles.saveButton}>
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default EditUserModal;
