import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../Tables.module.css';
import LeadHeader from '../LeadDetails/LeadHeader';

function BoqTable() {
  const location = useLocation();
  const navigate = useNavigate();
  const lead = location.state?.lead || {};
  const [boqs, setBoqs] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [role, setRole] = useState('user');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const storedPermissions = localStorage.getItem('permissions');
    const storedRole = localStorage.getItem('role');
    if (storedPermissions) {
      setPermissions(JSON.parse(storedPermissions));
    }
    if (storedRole) {
      setRole(storedRole);
    }
    fetchBoqs();
  }, []);

  const fetchBoqs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/boq/lead/${lead._id}`);
      const sortedBoqs = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt) // Sort by latest first
      );
      setBoqs(sortedBoqs);
    } catch (error) {
      console.error('There was an error fetching the BOQs!', error);
    }
  };

  const handleCreateQuotation = () => {
    navigate('/createboq', { state: { lead } });
  };

  return (
    <div className={styles.tableContainer}>
      <LeadHeader activeTab="boq" lead={lead} />

      <div className={styles.layoutBar} style={{ width: '100%', position: 'relative' }}>
        <button type="button" className={styles.createQuotationButton} onClick={handleCreateQuotation}>
          Create Quotation
        </button>
      </div>

      {/* BOQ Table */}
      <div className={styles.boqTable}>
        <h3>Uploaded BOQs</h3>
        {boqs.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>BOQ ID</th>
                <th>PDF URL</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {boqs.map((boq) => (
                <tr key={boq._id}>
                  <td>{boq.boqId}</td>
                  <td>
                    <a href={boq.pdfUrl} target="_blank" rel="noopener noreferrer">
                      View PDF
                    </a>
                  </td>
                  <td>
                    {new Date(boq.createdAt).toLocaleString('en-US', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </td>
                  <td>
                    <button
                      onClick={() => window.open(boq.pdfUrl, '_blank')}
                      className={styles.viewButton}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No BOQs Created for this lead.</p>
        )}
      </div>
    </div>
  );
}

export default BoqTable;
