import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './choosePackage.module.css';

const ChoosePackage = () => {
    const location = useLocation(); // Access passed state
    const navigate = useNavigate(); // For navigation to the next page
    const lead = location.state?.lead || {}; // Destructure lead data passed through state

    const [packages, setPackages] = useState([]); // State to store fetched packages
    const [selectedPackage, setSelectedPackage] = useState(null); // State to store selected package

    const [formDetails, setFormDetails] = useState({
        boqName: '',
        type: '',
        createdOn: new Date().toISOString().split('T')[0],
        customerName: lead.name || '', // Set customer name from lead data
        totalArea: '',
        percentageArea: '',
        areaToBeConstructed: '', // New field for calculated area
        numberOfFloors: 'G',
    });

    // Fetch packages from the backend API
    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await fetch('/api/packages'); // Replace with your API endpoint
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                setPackages(data);
            } catch (error) {
                console.error('Error fetching packages:', error);
            }
        };

        fetchPackages();
    }, []);

    // Handle input change for the form fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDetails((prev) => {
            const updatedDetails = { ...prev, [name]: value };

            // Calculate Area to be Constructed dynamically
            if (name === 'totalArea' || name === 'percentageArea') {
                const totalArea = parseFloat(updatedDetails.totalArea) || 0;
                const percentage = parseFloat(updatedDetails.percentageArea) || 0;
                const areaToBeConstructed = ((totalArea * percentage) / 100).toFixed(2);
                updatedDetails.areaToBeConstructed =
                    !isNaN(areaToBeConstructed) && areaToBeConstructed > 0 ? areaToBeConstructed : '';
            }

            return updatedDetails;
        });
    };

    // Handle package selection
    const handlePackageSelect = (pkg) => {
        setSelectedPackage(pkg);
        setFormDetails((prev) => ({
            ...prev,
            boqName: pkg.packageName, // Set BOQ Name as package name
            type: pkg.category, // Set Type as package category
        }));
    };

    // Dropdown options for No. of Floors
    const floorOptions = Array.from({ length: 11 }, (_, i) => `G${i > 0 ? `+${i}` : ''}`);

    // Handle Next button click
    const handleNext = () => {
        const dataToPass = {
            lead,
            selectedPackage,
            formDetails,
            areaToBeConstructed: formDetails.areaToBeConstructed,
        };
        navigate('/costbreakup', { state: dataToPass }); // Replace '/next-page' with your next route
    };

    return (
        <div className={styles.boqContainer}>
            {/* Section to Choose Package */}
            <h2 className={styles.boqHeading}>Choose Your Package</h2>
            <div className={styles.boqPackageList}>
                {packages.map((pkg) => (
                    <div
                        key={pkg._id}
                        className={`${styles.boqPackageCard} ${
                            selectedPackage?._id === pkg._id ? styles.boqActive : ''
                        }`}
                        onClick={() => handlePackageSelect(pkg)}
                    >
                        {/* Show Default Image */}
                        <img
                            src="https://t4.ftcdn.net/jpg/01/88/09/67/360_F_188096730_3hHZnyYI7zH7ATN4QJdNoZgMJDRAdbGW.jpg"
                            alt={pkg.packageName}
                            className={styles.boqPackageImage}
                        />
                        <div className={styles.boqPackageInfo}>
                            <p className={styles.boqPackageName}>{pkg.packageName}</p>
                            <p className={styles.boqPackagePrice}>₹ {pkg.price}</p>
                            <p className={styles.boqPackageCategory}>{pkg.category}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Form Section */}
            <div className={styles.boqFormSection}>
                <h3 className={styles.boqFormHeading}>Additional Details</h3>
                <div className={styles.boqInputRow}>
                    <div className={styles.boqInputGroup}>
                        <label>BOQ Name</label>
                        <input type="text" value={formDetails.boqName} name="boqName" readOnly />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>Type</label>
                        <input type="text" value={formDetails.type} name="type" readOnly />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>Created On</label>
                        <input type="date" value={formDetails.createdOn} name="createdOn" readOnly />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>Customer Name</label>
                        <input
                            type="text"
                            placeholder="Enter Name"
                            value={formDetails.customerName}
                            name="customerName"
                            readOnly
                        />
                    </div>
                </div>
                <div className={styles.boqInputRow}>
                    <div className={styles.boqInputGroup}>
                        <label>Total Area</label>
                        <input
                            type="number"
                            placeholder="Sq.Ft."
                            value={formDetails.totalArea}
                            name="totalArea"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>%age Area</label>
                        <input
                            type="number"
                            placeholder="%"
                            value={formDetails.percentageArea}
                            name="percentageArea"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>Area to be Constructed</label>
                        <input
                            type="number"
                            placeholder="Calculated Area"
                            value={formDetails.areaToBeConstructed}
                            name="areaToBeConstructed"
                            readOnly
                        />
                    </div>
                    <div className={styles.boqInputGroup}>
                        <label>No. of Floors</label>
                        <select
                            name="numberOfFloors"
                            value={formDetails.numberOfFloors}
                            onChange={handleInputChange}
                        >
                            {floorOptions.map((floor) => (
                                <option key={floor} value={floor}>
                                    {floor}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {/* Next Button */}
            <div className={styles.boqButtonSection}>
                <button className={styles.boqNextButton} onClick={handleNext}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default ChoosePackage;
