import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import html2pdf from "html2pdf.js";
import styles from "./previewPDF.module.css";
import TermsAndConditions from "./TermsAndConditions";

const PreviewPDF = () => {
  const location = useLocation();
  const {
    costBreakupItems = [],
    totalCost = 0,
    selectedItems = [],
    selectedPackage = {},
    formDetails = {},
    lead = {},
  } = location.state || {};

  const pdfRef = useRef();
  const [siteInspectionData, setSiteInspectionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (!formDetails.customerName || !selectedPackage.packageName) {
      console.warn("Data for customer or package is missing.");
    }
  }, [formDetails, selectedPackage]);

  useEffect(() => {
    if (lead._id) {
      fetchSiteInspectionData(lead._id);
    }
  }, [lead._id]);

  const fetchSiteInspectionData = async (leadId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/siteinspection/lead/${leadId}`);
      console.log("Site Inspection Data Response:", response.data);
      setSiteInspectionData(response.data);
    } catch (error) {
      console.error("Error fetching site inspection data:", error);
      if (error.response?.status === 404) {
        alert("Site inspection data not found for this lead.");
      } else {
        alert("An error occurred while fetching site inspection data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const compressImage = async (url) => {
    try {
      const img = await fetch(url);
      const blob = await img.blob();

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const image = await new Promise((resolve) => {
        const imgElement = new Image();
        imgElement.onload = () => resolve(imgElement);
        imgElement.src = URL.createObjectURL(blob);
      });

      const maxWidth = 1080;
      const scale = maxWidth / image.width;
      canvas.width = maxWidth;
      canvas.height = image.height * scale;

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      return canvas.toDataURL("image/jpeg", 0.7); // Adjust quality to compress further
    } catch (error) {
      console.error("Error compressing image:", error);
      return url; // Return original if compression fails
    }
  };

  const handleSavePDF = async () => {
    const element = pdfRef.current;

    setSaving(true);
    setPdfUrl(null);

    const filename = `${formDetails.customerName || "Customer"}_Housebanao_${lead.leadId || "LeadID"}.pdf`;

    const options = {
      margin: 0.5,
      filename, // Dynamic naming
      image: { type: "jpeg", quality: 0.7 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    try {
      // Generate PDF as Blob
      const pdfBlob = await html2pdf().set(options).from(element).outputPdf("blob");

      // Validate Blob
      if (!pdfBlob || pdfBlob.size === 0) {
        throw new Error("Generated PDF is empty or invalid.");
      }

      // Directly trigger a download to validate locally
      const blobUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Upload to the server
      const formData = new FormData();
      const file = new File([pdfBlob], filename, { type: "application/pdf" });
      formData.append("file", file);
      formData.append("leadId", lead._id);
      formData.append("boqId", formDetails.boqId || ""); // Pass BOQ ID if available

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload-pdf`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { url } = response.data;
      setPdfUrl(url);
      alert("PDF saved successfully!");
    } catch (error) {
      console.error("Error saving PDF:", error);
      alert("Failed to save the PDF. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const handleDownloadPDF = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${formDetails.customerName || "Customer"}_Housebanao_${lead.leadId || "LeadID"}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("PDF not saved yet.");
    }
  };

  return (
    <div className={styles.previePDFcontainer}>
      {loading ? (
        <p>Loading site inspection data...</p>
      ) : (
        <div ref={pdfRef} className={styles.pdfContent}>
          {/* Company Header */}
          <div className={styles.companyHeader}>
            <img
              src="/housebanao.svg"
              alt="Company Logo"
              className={styles.companyLogo}
            />
            <div className={styles.companyDetails}>
              <h1>Housebanao Pvt. Ltd.</h1>
              <p>
                7th Floor, JMD Megapolish, 746A, Badshahpur Sohna Rd,
                <br /> Sector 48, Gurugram, Haryana, 122018
              </p>
              <p>Email: contact@housebanao.com | Phone: +91-1234567890</p>
            </div>
          </div>
          <hr />

          {/* Header Section */}
          <div className={styles.header}>
            <div className={styles.headerDetails}>
              <h3>
                {formDetails.customerName || lead.name || "N/A"} (Customer ID: {lead.leadId || "N/A"})
              </h3>
              <p>
                <strong>Name:</strong> {formDetails.customerName || lead.name || "Name not provided"}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {formDetails.address ||
                  [lead.addressLine1, lead.addressLine2, lead.city, lead.state]
                    .filter(Boolean)
                    .join(", ") || "Address not provided"}
              </p>
              <p>
                <strong>Phone no:</strong> {formDetails.phone || lead.number || "Phone number not provided"}
              </p>
              <p>
                <strong>Email:</strong> {lead.email || "Email not provided"}
              </p>
            </div>
            <div className={styles.date}>
              <p>
                <strong>Date:</strong> {formDetails.createdOn || new Date(lead.createdDate).toLocaleDateString() || "N/A"}
              </p>
            </div>
          </div>

          {/* Image Section */}
          <div className={styles.imageSection}>
            <img
              src={formDetails.imageUrl || lead.imageUrl || "default-house.jpg"}
              alt="House Design"
              className={styles.houseImage}
            />
          </div>

          {/* Why Choose Us Section */}
          <div className={styles.whyChooseUs}>
            <h4>Why choose us?</h4>
            <p>
              Welcome to House Banao, where we specialize in crafting homes from inception to completion.
              With a focus on quality craftsmanship and client satisfaction, we offer end-to-end construction
              services tailored to your vision. From initial consultation to final walkthrough, our dedicated
              team ensures every detail is executed with precision and care. At House Banao, we're not just
              building houses; we're creating havens where families thrive and memories are cherished.
            </p>
          </div>

          {/* New Table Layout Section */}
          <div className={styles.newTableSection}>
            <h4>Ground Floor 1st Floor 2nd Floor Specification</h4>
            <table className={styles.specificationTable}>
              <tbody>
                <tr>
                  <td>Package Name</td>
                  <td>{selectedPackage.packageName || "N/A"}</td>
                </tr>
                <tr>
                  <td>Package Rate</td>
                  <td>INR&nbsp;{selectedPackage.price || "N/A"}/-</td>
                </tr>
                <tr>
                  <td>Total Build-up Area</td>
                  <td>{formDetails.totalArea || lead.plotSize || "N/A"} Sq. Ft.</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Cost Breakdown Section */}
          <h4>Cost Breakdown:</h4>
          {costBreakupItems.length > 0 ? (
            <table className={styles.breakupTable}>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Quantity/Area</th>
                  <th>Rate</th>
                  <th>Cost (INR)</th>
                </tr>
              </thead>
              <tbody>
                {costBreakupItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item}</td>
                    <td>{item.type || "N/A"}</td>
                    <td>{item.calculatedValue}</td>
                    <td>{item.rate}</td>
                    <td>{item.cost}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4" style={{ textAlign: "right", fontWeight: "bold" }}>
                    Total Cost
                  </td>
                  <td style={{ fontWeight: "bold" }}>₹{totalCost}</td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <p>No cost breakdown data available.</p>
          )}

          {/* Site Inspection Section */}
          {siteInspectionData && (
            <div className={styles.pageBreak}>
              <div className={styles.siteInspectionSection}>
                <h4>Site Inspection Details</h4>
                <p>
                  <strong>Created By:</strong> {siteInspectionData.createdBy || "N/A"}
                </p>
                <p>
                  <strong>Date:</strong> {siteInspectionData.date
                    ? new Date(siteInspectionData.date).toLocaleDateString()
                    : "N/A"}
                </p>
                <table className={styles.siteInspectionTable}>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Selection</th>
                      <th>UOM</th>
                      <th>Yes/No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {siteInspectionData.answers.map((answer, index) => (
                      <tr key={index}>
                        <td>{answer.question || "N/A"}</td>
                        <td>{answer.selection || "N/A"}</td>
                        <td>{answer.uom || "N/A"}</td>
                        <td>{answer.yesNo || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* Terms and Conditions */}
          <div className={styles.pageBreak}>
            <TermsAndConditions />
          </div>
        </div>
      )}

      <div className={styles.buttonContainer}>
        {saving ? (
          <p>Saving...</p>
        ) : pdfUrl ? (
          <button onClick={handleDownloadPDF} className={styles.generateButton}>
            Download PDF
          </button>
        ) : (
          <button onClick={handleSavePDF} className={styles.generateButton}>
            Save PDF
          </button>
        )}
      </div>
    </div>
  );
};

export default PreviewPDF;
