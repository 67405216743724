import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import styles from "./choosePackageItem.module.css";

const ChoosePackageItem = () => {
  const location = useLocation();
  const {
    costBreakupItems = [],
    totalCost,
    formDetails,
    selectedPackage,
    areaToBeConstructed,
    lead = {},
  } = location.state || {};

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log("Lead Data:", lead);
    console.log("Received costBreakupItems in ChoosePackageItem:", costBreakupItems);
  }, [lead, costBreakupItems]);

  useEffect(() => {
    if (selectedPackage?.items && items.length === 0) {
      setItems(selectedPackage.items || []);
      const preselected = (selectedPackage.items || []).slice(0, 2);
      setSelectedItems(preselected);
    }
  }, [selectedPackage, items]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) =>
      prev.find((selected) => selected._id === item._id)
        ? prev.filter((selected) => selected._id !== item._id)
        : [...prev, item]
    );
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleClickOutside = (event) => {
    if (event.target.classList.contains(styles.choosePkgItemRightModalOverlay)) {
      handleCloseModal();
    }
  };

  const calculateTotalCost = () => {
    return costBreakupItems.reduce((acc, item) => acc + (parseFloat(item.cost) || 0), 0).toFixed(2);
  };

  const handlePreview = () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one item to proceed.");
      return;
    }

    if (!costBreakupItems || costBreakupItems.length === 0) {
      alert("Cost breakdown data is missing.");
      return;
    }

    navigate("/preview", {
      state: {
        costBreakupItems,
        totalCost: calculateTotalCost(),
        selectedItems,
        selectedPackage,
        formDetails,
        areaToBeConstructed,
        lead,
      },
    });
  };

  return (
    <div className={`${styles.choosePkgItemContainer} ${isModalOpen ? styles.choosePkgItemModalOpen : ""}`}>
      <button className={styles.choosePkgItemBackButton} onClick={() => navigate(-1)}>
        ← Back
      </button>

      <div className={styles.choosePkgItemLeftSection}>
        <h2>Package Items</h2>
        <div className={styles.choosePkgItemSelectedItemsList}>
          {selectedItems.map((item) => (
            <div key={item._id} className={styles.choosePkgItemSelectedItemCard}>
              <div className={styles.choosePkgItemItemDetails}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <h4>{item.itemName}</h4>
                  <button
                    className={styles.choosePkgItemRemoveButton}
                    onClick={() => handleCheckboxChange(item)}
                  >
                    <FaTrashAlt className={styles.choosePkgItemTrashIcon} />
                  </button>
                </div>
                <textarea
                  placeholder="Item Description"
                  defaultValue={item.itemDetails}
                  className={styles.choosePkgItemItemDescription}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.choosePkgItemButtonGroup}>
        <button className={styles.choosePkgItemAddItemButton} onClick={() => setIsModalOpen(true)}>
          Add Item
        </button>
        <div className={styles.choosePkgItemRightButtons}>
          <button className={styles.choosePkgItemSaveDraftButton}>Save Draft</button>
          <button className={styles.choosePkgItemViewPdfButton} onClick={handlePreview}>
            Preview PDF
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div
          className={`${styles.choosePkgItemRightModalOverlay} ${isModalOpen ? styles.choosePkgItemOpen : ""}`}
          onClick={handleClickOutside}
        >
          <div className={`${styles.choosePkgItemRightModal} ${isModalOpen ? styles.choosePkgItemOpen : ""}`}>
            <h3 className={styles.choosePkgItemModalHeading}>
              Select Items
              <button className={styles.choosePkgItemCloseButton} onClick={handleCloseModal}>
                &times;
              </button>
            </h3>
            <div className={styles.choosePkgItemItemsList}>
              {items.map((item) => (
                <label key={item._id} className={styles.choosePkgItemItemCard}>
                  <input
                    type="checkbox"
                    checked={selectedItems.some((selected) => selected._id === item._id)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <div className={styles.choosePkgItemItemDetails}>
                    <h4>{item.itemName}</h4>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChoosePackageItem;
