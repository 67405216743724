import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Boqone.module.css";
import sampleImage from "../../../assets/packagegirl.png";

const BoqOne = ({ onSelectCategory, onEditPackage }) => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get("/api/packages"); // Replace with your API endpoint
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  const handleEdit = (pkg) => {
    console.log(`Editing package with ID: ${pkg._id}`);
    onEditPackage(pkg); // Pass the selected package data to the parent component
  };

  return (
    <form className={styles.form}>
      <h2 className={styles.heading}>Choose Your Category</h2>
      <div className={styles.categories}>
        <div className={styles.row}>
          <div
            className={styles.box}
            onClick={() => onSelectCategory("Construction")}
          >
            Construction
          </div>
          <div
            className={styles.box}
            onClick={() => onSelectCategory("Home Interior")}
          >
            Home Interior
          </div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.box}
            onClick={() => onSelectCategory("Construction + Interior")}
          >
            Construction + Interior
          </div>
          <div
            className={styles.box}
            onClick={() => onSelectCategory("Office Interior")}
          >
            Office Interior
          </div>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : packages.length > 0 ? (
        <div className={styles.packages}>
          {packages.map((pkg) => (
            <div key={pkg._id} className={styles.packageBox}>
              <h3>{pkg.packageName}</h3>
              <p>Price: {pkg.price ? `INR/-${pkg.price}` : "N/A"}</p>
              <p>Category: {pkg.category}</p>
              <button
                type="button"
                className={styles.editButton}
                onClick={() => handleEdit(pkg)}
              >
                Edit
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.imageContainer}>
          <img src={sampleImage} alt="Sample" className={styles.image} />
        </div>
      )}
      <button
        type="button"
        className={styles.button}
        onClick={() => onSelectCategory("Create New")}
      >
        Create New
      </button>
    </form>
  );
};

export default BoqOne;
