import React from "react";
import Modal from "react-modal";
import styles from "../Tables.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function AddUserModal({
  isOpen,
  onRequestClose,
  formData,
  errors,
  passwordVisible,
  currentStep,
  handleChange,
  setPasswordVisible,
  nextStep,
  prevStep,
  handlePermissionChange,
  handleSubmit,
  closeModal,
}) {
  const validateInput = (e) => {
    const { name, value } = e.target;
    let error = "";

    switch (name) {
      case "name":
        if (!/^[A-Za-z\s]*$/.test(value)) {
          error = "Name can only contain letters.";
        } else if (value.length > 30) {
          error = "Name must not exceed 30 characters.";
        }
        break;

      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits.";
        }
        break;

      case "email":
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = "Invalid email format.";
        }
        break;

      case "password":
        if (value.length < 6) {
          error = "Password must be at least 6 characters long.";
        } else if (!/[A-Z]/.test(value)) {
          error = "Password must contain at least one uppercase letter.";
        } else if (!/[a-z]/.test(value)) {
          error = "Password must contain at least one lowercase letter.";
        } else if (!/[0-9]/.test(value)) {
          error = "Password must contain at least one digit.";
        }
        break;

      case "confirmPassword":
        if (value !== formData.password) {
          error = "Passwords do not match.";
        }
        break;

      case "department":
        if (value === "") {
          error = "Please select a department.";
        }
        break;

      case "designation":
        if (value === "") {
          error = "Please select a designation.";
        }
        break;

      default:
        break;
    }

    handleChange(e);
    if (error) {
      errors[name] = error;
    } else {
      delete errors[name];
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add User Modal"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalContent}>
        <h2>Add User</h2>
        {currentStep === 1 && (
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              nextStep();
            }}
          >
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={validateInput}
              className={errors.name ? styles.errorInput : ""}
            />
            {errors.name && <p className={styles.error}>{errors.name}</p>}

            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="9876543210"
              value={formData.phoneNumber}
              onChange={validateInput}
              className={errors.phoneNumber ? styles.errorInput : ""}
            />
            {errors.phoneNumber && (
              <p className={styles.error}>{errors.phoneNumber}</p>
            )}

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@gmail.com"
              value={formData.email}
              onChange={validateInput}
              className={errors.email ? styles.errorInput : ""}
            />
            {errors.email && <p className={styles.error}>{errors.email}</p>}

            <label htmlFor="department">Department</label>
<select
  id="department"
  name="department"
  value={formData.department}
  onChange={validateInput}
  className={errors.department ? styles.errorInput : ""}
>
  <option value="">Select Department</option>
  <option value="Insight Sales">Insight Sales</option>
  <option value="Head of Insight Sales">Head of Insight Sales</option>
  <option value="Junior of Insight Sales">Junior of Insight Sales</option>
  <option value="Intern">Intern</option>
  <option value="Head Sales">Head Sales</option>
  <option value="Head">Head</option>
  <option value="Vertical Head">Vertical Head</option>
  <option value="Executive">Executive</option>
  <option value="Senior Sales Manager">Senior Sales Manager</option>
  <option value="HR">HR</option>
  <option value="Tech">Tech</option>
  <option value="Accounts">Accounts</option>
  <option value="Marketing">Marketing</option>
  <option value="Digital Marketing">Digital Marketing</option>
  <option value="Content Writer">Content Writer</option>
  <option value="Content Creator">Content Creator</option>
</select>

            {errors.department && (
              <p className={styles.error}>{errors.department}</p>
            )}

            <label htmlFor="designation">Designation</label>
            <select
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={validateInput}
              className={errors.designation ? styles.errorInput : ""}
            >
              <option value="">Select Designation</option>
              <option value="Junior">Junior</option>
              <option value="Senior">Senior</option>
            </select>
            {errors.designation && (
              <p className={styles.error}>{errors.designation}</p>
            )}

            <label htmlFor="password">Password</label>

            <div className={styles.passwordWrapper}>
  <input
    type={passwordVisible ? "text" : "password"}
    id="password"
    name="password"
    placeholder="Enter password"
    value={formData.password}
    onChange={validateInput}
    className={`${styles.inputField} ${errors.password ? styles.errorInput : ""}`}
  />
  <span
    className={styles.eyeIcon}
    onClick={() => setPasswordVisible(!passwordVisible)}
  >
    <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
  </span>
</div>

            {errors.password && (
              <p className={styles.error}>{errors.password}</p>
            )}

            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm password"
              value={formData.confirmPassword}
              onChange={validateInput}
              className={errors.confirmPassword ? styles.errorInput : ""}
            />
            {errors.confirmPassword && (
              <p className={styles.error}>{errors.confirmPassword}</p>
            )}

            <div className={styles.formGroupBottom}>
              <button
                type="button"
                onClick={closeModal}
                className={styles.closeButton}
              >
                Close
              </button>
              <button type="submit" className={styles.nextButton}>
                Next
              </button>
            </div>
          </form>
        )}
        {currentStep === 2 && (
          <div>
            <h3>Permissions</h3>
            <table className={styles.permissionsTable} style={{ marginTop: "10px" }}>
              <thead>
                <tr>
                  <th>Access</th>
                  <th>Editor</th>
                  <th>Viewer</th>
                </tr>
              </thead>
              <tbody>
  {Object.entries(formData.permissions).map(([category, permission]) => (
    <tr key={category}>
      <td>{category.charAt(0).toUpperCase() + category.slice(1)}</td>
      <td>
        <input
          type="checkbox"
          checked={permission.editor}
          onChange={(e) => handlePermissionChange(e, category, "editor")}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={permission.viewer}
          onChange={(e) => handlePermissionChange(e, category, "viewer")}
        />
      </td>
    </tr>
  ))}
</tbody>

            </table>
            <div className={styles.formGroupBottom}>
              <button
                type="button"
                onClick={prevStep}
                className={styles.backButton}
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className={styles.saveButton}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddUserModal;
