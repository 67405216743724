import React, { useState } from "react";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import BoqOne from "../components/BoqStructure/BoqOne/BoqOne";
import BoqTwo from "../components/BoqStructure/BoqTwo/BoqTwo";
import styles from "./CreatePackage.module.css";

const CreatePackage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null); // For editing an existing package

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedPackage(null); // Reset previous edits
    setCurrentStep(2);
  };

  const handleEditPackage = (pkg) => {
    setSelectedPackage(pkg);
    setSelectedCategory(pkg.category); // Set category from the selected package
    setCurrentStep(2);
  };

  const handleBoqSave = (data) => {
    console.log("Saved data:", data);
    setCurrentStep(1); // Navigate back to BoqOne
  };

  return (
    <div className={styles.container}>
      <SideNavigation />
      <div className={styles.content}>
        {currentStep === 1 && (
          <BoqOne
            onSelectCategory={handleCategorySelect}
            onEditPackage={handleEditPackage}
          />
        )}
        {currentStep === 2 && (
          <BoqTwo
            onBack={() => setCurrentStep(1)}
            onSave={handleBoqSave}
            defaultCategory={selectedCategory}
            initialPackages={selectedPackage ? [selectedPackage] : []}
          />
        )}
      </div>
    </div>
  );
};

export default CreatePackage;
