import React from "react";
import styles from "./termsAndConditions.module.css";

const TermsAndConditions = () => {
  return (
    <div className={styles.termsContainer}>
      <h4>Points to be Noted</h4>
      <ol>
        <li>
          This is a Specification and not a Contract. This will be converted
          into a contract once the customer starts the project with Housebanao.
        </li>
        <li>
          The quotation is marked as final (will not change) only when a minimum
          of 5% of the total project value is received as a booking amount.
          Post this, it is valid for the next 30 days.
        </li>
        <li>
          The quotation value will change if the customer adds, removes, or
          updates any specification.
        </li>
        <li>
          At Housebanao, we have zero tolerance for delays. In the contract,
          there are strict compensation clauses for any delay in a project by
          the professional (contractor), where the customer is compensated.
          A compensation calculation table is provided for reference as part of
          this quotation.
        </li>
        <li>
          The quotation is prepared based on optimal structural designs
          following all relevant IS codes for construction. Customized designs
          may levy an additional fee.
        </li>
        <li>
          The total compensation for delay shall be the lower value between the
          last milestone value or 2.55% of the total project value.
        </li>
        <li>
          The following activities are undertaken by our Construction
          Professionals post the payment of the booking amount: Site
          Reconnaissance, Soil Testing, Structural Drawings, Approval of
          Structural Drawings, Contract Drafting, and Site Meetings. It takes
          about 21 days to complete all these activities, and work on the site
          can commence only after completing all these activities.
        </li>
        <li>
          Construction will be carried out as per the specifications, dimensions,
          and quantities mentioned in this quotation. Differential charges will
          be applicable for any change in specifications, dimensions, or
          quantities as per the actual calculations and rates mutually agreed
          upon in writing by the Customer and Contractor.
        </li>
        <li>
          All such changes shall be routed through Housebanao, and the value of
          this Quotation for all purposes would be considered inclusive of the
          differential charges for these changes. All terms and conditions
          defined in the vendor onboarding agreement between the Contractor and
          Housebanao will apply to the revised value of the quotation.
        </li>
        <li>
          All payments are required to be transferred to the company's dedicated
          escrow account number allocated to the owner. No cash payments will
          be accepted. Any cash payments made by the owner to anyone are the
          owner's responsibility, and the company will not be held responsible
          for any losses thereof.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
