import PreviewPDF from "../components/QuotationGenerator/previewPDF";

import styles from "./Pages.module.css";

const Preview = () => {

  return (
    <div className={styles.container}>
      <PreviewPDF />
    </div>
  );
};

export default Preview;
