import React from 'react';
import CsvToJsonComponent from "../components/CsvToJsonComponent/CsvToJsonComponent";

const Import = () => {
  return (
    <div>
      <h1>Start Importing</h1>
      <CsvToJsonComponent />
    </div>
  );
};

export default Import;
