import React, { useState } from 'react';
import axios from 'axios';
import csvtojson from 'csvtojson';
import './CsvToJsonComponent.css';

const CsvToJsonComponent = () => {
  const [progress, setProgress] = useState(''); // Progress messages
  const [error, setError] = useState(null); // Error messages
  const [loading, setLoading] = useState(false); // Loading state
  const [leadsLog, setLeadsLog] = useState([]); // Log of processed leads
  const [summary, setSummary] = useState(null); // Summary of the insertion process

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProgress('Uploading file...');
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csvData = event.target.result;
        try {
          setProgress('Converting CSV to JSON...');
          const json = await csvtojson({ delimiter: '\t' }).fromString(csvData);
          const cleanedData = json.map((item) => {
            if (item.phone_number) {
              item.phone_number = item.phone_number.replace(/^p:/i, '');
            }
            if (item.number) {
              item.number = item.number.replace(/^p:/i, '');
            }
            return item;
          });

          setProgress('JSON conversion complete. Processing leads...');
          setError(null);
          await insertLeadsOneByOne(cleanedData);
        } catch (err) {
          setError('Error parsing CSV');
        }
      };
      reader.readAsText(file);
    }
  };

  const insertLeadsOneByOne = async (leads) => {
    try {
      setLoading(true);
      setProgress('Starting insertion process...');
      const log = [];
      let uniqueLeadsCount = 0;
      let duplicateLeadsCount = 0;

      for (let i = 0; i < leads.length; i++) {
        const lead = leads[i];

        if (!lead.full_name || !lead.phone_number || !lead.email) {
          log.push({
            message: `Skipped lead due to missing fields: ${JSON.stringify(lead)}`,
            success: false,
          });
          continue;
        }

        const shortFields = {
          name: lead.full_name,
          number: lead.phone_number,
          email: lead.email,
        };

        try {
          const response = await axios.post(`${API_BASE_URL}/insert-leads`, [shortFields]);
          if (response.status === 200) {
            const { uniqueLeadsCount: newUniqueCount, duplicateLeadsCount: newDuplicateCount } = response.data;

            uniqueLeadsCount += newUniqueCount || 0;
            duplicateLeadsCount += newDuplicateCount || 0;

            log.push({
              message: `Lead inserted for: ${shortFields.name}`,
              success: true,
            });
          } else {
            log.push({
              message: `Failed to insert lead: ${shortFields.name}`,
              success: false,
            });
          }
        } catch (err) {
          log.push({
            message: `Error inserting lead: ${shortFields.name}`,
            success: false,
          });
        }

        setProgress(`Processed ${i + 1} of ${leads.length} leads`);
        setLeadsLog([...log]);
      }

      setSummary({ uniqueLeadsCount, duplicateLeadsCount, totalProcessed: leads.length });
      setProgress('All leads processed.');
    } catch (err) {
      setError('Error inserting leads');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="csv-container">
      <h2 className="csv-title">Import Leads</h2>
      <div className="csv-upload">
        <label htmlFor="file-upload" className="upload-label">
          Upload CSV File
        </label>
        <input id="file-upload" type="file" accept=".csv" onChange={handleFileUpload} className="file-input" />
      </div>
      {loading && <div className="spinner"></div>}
      {progress && <p className="progress">{progress}</p>}
      {error && <p className="error">{error}</p>}

      <div className="csv-log">
        <strong>Processing Log</strong>
        <ul>
          {leadsLog.map((log, index) => (
            <li key={index} className={log.success ? 'log-success' : 'log-error'}>
              {log.message}
            </li>
          ))}
        </ul>
      </div>

      {summary && (
        <div className="csv-summary">
          <h3>Summary</h3>
          <div className="csv-summary-box">
            <p>Total Leads Processed: {summary.totalProcessed}</p>
            <p className="log-success">
            <span className="highlight">✅ Unique Leads Inserted:{summary.uniqueLeadsCount}</span>
            </p>
            <p className="log-error">
              ⚠️ Duplicate Leads Skipped: {summary.duplicateLeadsCount}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvToJsonComponent;
