import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./costBreakup.module.css";
import { FaTrash } from "react-icons/fa";

const CostBreakup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    formDetails = {},
    selectedPackage = {},
    areaToBeConstructed = 0,
  } = location.state || {};

  const [rows, setRows] = useState([]);
  const hasInitializedRows = useRef(false);

  const parseNumberOfFloors = (floors) => {
    if (floors.startsWith("G")) {
      return floors === "G" ? 1 : parseInt(floors.replace("G+", ""), 10) + 1;
    }
    return parseInt(floors, 10);
  };

  const initializeRows = useCallback(() => {
    if (hasInitializedRows.current) return;

    const numberOfFloors = parseNumberOfFloors(formDetails.numberOfFloors || "1");
    const totalArea = parseFloat(areaToBeConstructed) || 0;

    const initialRows = Array.from({ length: numberOfFloors }, (_, i) => ({
      item: `Floor ${i + 1}`,
      calculatedValue: totalArea.toFixed(2),
      type: "Area",
      rate: selectedPackage?.price || "",
      cost: (totalArea * (selectedPackage?.price || 0)).toFixed(2),
      _id: `floor-${i + 1}-${Date.now()}`,
    }));

    setRows(initialRows);
    hasInitializedRows.current = true;
  }, [formDetails, selectedPackage, areaToBeConstructed]);

  useEffect(() => {
    initializeRows();
  }, [initializeRows]);

  const handleChange = (index, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index][field] = value;

      if (updatedRows[index].calculatedValue && updatedRows[index].rate) {
        updatedRows[index].cost = (
          parseFloat(updatedRows[index].calculatedValue) *
          parseFloat(updatedRows[index].rate)
        ).toFixed(2);
      } else {
        updatedRows[index].cost = "";
      }

      return updatedRows;
    });
  };

  const handleDeleteRow = (rowId) => {
    setRows((prevRows) => prevRows.filter((row) => row._id !== rowId));
  };

  const handleAddField = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        item: "",
        calculatedValue: "",
        type: "Custom",
        rate: "",
        cost: "",
        _id: `custom-${Date.now()}`,
      },
    ]);
  };

  const calculateTotalCost = () => {
    return rows.reduce((total, row) => total + (parseFloat(row.cost) || 0), 0).toFixed(2);
  };

  const handleProceed = () => {
    if (rows.length === 0) {
      alert("No rows to proceed.");
      return;
    }

    navigate("/packageitem", {
      state: {
        costBreakupItems: rows,
        totalCost: calculateTotalCost(),
        formDetails,
        selectedPackage,
        areaToBeConstructed,
        lead: location.state?.lead || {},
      },
    });
  };

  return (
    <div className={styles.container}>
      <h2>Cost Breakdown</h2>
      <table className={styles.costTable}>
        <thead>
          <tr>
            <th>Item</th>
            <th>Type</th>
            <th>Calculated Area</th>
            <th>Rate</th>
            <th>Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row._id}>
              <td>
                <input
                  type="text"
                  value={row.item}
                  onChange={(e) => handleChange(index, "item", e.target.value)}
                  placeholder="Enter Item Name"
                  className={styles.greyInput}
                />
              </td>
              <td>
                <select
                  value={row.type}
                  onChange={(e) => handleChange(index, "type", e.target.value)}
                  className={styles.greyInput}
                >
                  <option value="Area">Area</option>
                  <option value="Quantity">Quantity</option>
                  <option value="Custom">Custom</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.calculatedValue}
                  onChange={(e) => handleChange(index, "calculatedValue", e.target.value)}
                  placeholder="Enter Area/Quantity"
                  className={styles.greyInput}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.rate}
                  onChange={(e) => handleChange(index, "rate", e.target.value)}
                  placeholder="Enter Rate"
                  className={styles.greyInput}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.cost}
                  readOnly
                  placeholder="Calculated Cost"
                  className={styles.greyInput}
                />
              </td>
              <td>
                <button
                  onClick={() => handleDeleteRow(row._id)}
                  className={styles.deleteButton}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.totalRow}>
        <strong>Total Cost:</strong> ₹{calculateTotalCost()}
      </div>

      <div className={styles.buttonGroup}>
        <button className={styles.addButton} onClick={handleAddField}>
          Add Field
        </button>
        <button className={styles.proceedButton} onClick={handleProceed}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default CostBreakup;
