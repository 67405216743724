import React, { useState, useCallback } from "react";
import styles from "./Boqtwo.module.css";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating temporary IDs

const BoqTwo = ({ onBack, onSave, defaultCategory, initialPackages = [] }) => {
  const [packages, setPackages] = useState(
    initialPackages.length > 0
      ? initialPackages
      : [
          {
            packageName: defaultCategory || "",
            price: "",
            packageDetails: "",
            items: [{ itemName: "", itemDetails: "", uploadedImage: null }],
          },
        ]
  );

  // Temporary state for storing selected image files
  const [imageFiles, setImageFiles] = useState({});

  // Update a specific field for package or item
  const handleFieldChange = useCallback((pkgIndex, itemIndex, field, value) => {
    setPackages((prevPackages) => {
      const updatedPackages = [...prevPackages];
      if (itemIndex >= 0) {
        updatedPackages[pkgIndex].items[itemIndex][field] = value;
      } else {
        updatedPackages[pkgIndex][field] = value;
      }
      return updatedPackages;
    });
  }, []);

  // Handle storing the selected image file in the state
  const handleImageUpload = useCallback((pkgIndex, itemIndex, e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImageFiles((prevFiles) => ({
      ...prevFiles,
      [`${pkgIndex}-${itemIndex}`]: file,  // Use a unique key based on package and item index
    }));
  }, []);

  // Handle adding a new item to a package
  const handleAddItem = (index) => {
    setPackages((prevPackages) => {
      const updatedPackages = [...prevPackages];
      updatedPackages[index].items.push({
        _id: uuidv4(), // Assign a temporary ID for the new item
        itemName: "",
        itemDetails: "",
        uploadedImage: null,
      });
      return updatedPackages;
    });
  };

  // Handle form submission (save the packages)
const handleSubmit = async (e) => {
  e.preventDefault();

  const updatedPackages = packages.map((pkg) => ({
    ...pkg,
    category: defaultCategory,
    price: parseFloat(pkg.price), // Ensure it's a number
  }));

  console.log("Submitting packages:", updatedPackages); // Debug log

  try {
    const response = await fetch("/api/packages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ packages: updatedPackages }), // Send as { packages: [...] }
    });

    const result = await response.json();
    if (response.ok) {
      alert("Packages saved successfully!");
    } else {
      alert(result.message || "Failed to save packages!");
    }
  } catch (error) {
    console.error("Error saving packages:", error);
  }
};

  

  return (
    <form className={styles.boqTwoForm} onSubmit={handleSubmit}>
      <div className={styles.boqTwoHeader}>
        <div className={styles.boqTwoLeftHeader}>
          <span className={styles.boqTwoBackIcon} onClick={onBack}>
            ←
          </span>
          <h2 className={styles.boqTwoHeadingText}>
            Add Packages {defaultCategory && `(${defaultCategory})`}
          </h2>
        </div>
      </div>

      {packages.map((pkg, pkgIndex) => (
        <div key={`package-${pkgIndex}`} className={styles.boqTwoPackageRow}>
          <div className={styles.boqTwoInputRow}>
            <div className={styles.boqTwoInputGroup}>
              <label className={styles.boqTwoLabel}>Package Name</label>
              <input
                type="text"
                value={pkg.packageName}
                onChange={(e) => handleFieldChange(pkgIndex, -1, "packageName", e.target.value)}
                className={styles.boqTwoInput}
                placeholder="Enter package name"
              />
            </div>
            <div className={styles.boqTwoInputGroup}>
              <label className={styles.boqTwoLabel}>Price/Price Range</label>
              <input
                type="number"
                value={pkg.price}
                onChange={(e) => handleFieldChange(pkgIndex, -1, "price", e.target.value)}
                className={styles.boqTwoInput}
                placeholder="In Rupees"
              />
            </div>
          </div>

          <div className={styles.boqTwoTextAreaGroup}>
            <label className={styles.boqTwoLabels}>Package Details</label>
            <textarea
              value={pkg.packageDetails}
              onChange={(e) => handleFieldChange(pkgIndex, -1, "packageDetails", e.target.value)}
              className={styles.boqTwoTextArea}
              placeholder="Description"
            />
          </div>
          <hr />

          {pkg.items.map((item, itemIndex) => (
            <div key={`item-${itemIndex}`} className={styles.boqTwoItemRow}>
              <div className={styles.boqTwoInputRow}>
                <div className={styles.boqTwoInputGroup}>
                  <label className={styles.boqTwoLabel}>Item Name</label>
                  <input
                    type="text"
                    value={item.itemName}
                    onChange={(e) => handleFieldChange(pkgIndex, itemIndex, "itemName", e.target.value)}
                    className={styles.boqTwoInput}
                    placeholder="Enter item name"
                  />
                </div>
                <div className={styles.boqTwoInputGroup}>
                  <label className={styles.boqTwoLabel}>Upload Image</label>
                  <input
                    type="file"
                    id={`file-input-${pkgIndex}-${itemIndex}`}  // Unique ID for each file input
                    onChange={(e) => handleImageUpload(pkgIndex, itemIndex, e)}
                    className={styles.boqTwoInput}
                    accept="image/*"
                  />
                </div>
              </div>

              <div className={styles.boqTwoTextAreaGroup}>
                <label className={styles.boqTwoLabels}>Item Details</label>
                <textarea
                  value={item.itemDetails}
                  onChange={(e) => handleFieldChange(pkgIndex, itemIndex, "itemDetails", e.target.value)}
                  className={styles.boqTwoTextArea}
                  placeholder="Description"
                />
              </div>
            </div>
          ))}

          <button
            type="button"
            className={styles.boqTwoAddButton}
            onClick={() => handleAddItem(pkgIndex)}
          >
            <FaPlus /> Add Item
          </button>
        </div>
      ))}

      <div className={styles.boqTwoInputRow}>
        <button type="submit" className={styles.boqTwoSaveButton}>
          Save
        </button>
      </div>
    </form>
  );
};

export default BoqTwo;
